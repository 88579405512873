// Supports weights 100-900
import "@fontsource-variable/outfit";
// Supports weights 100-700
import "@fontsource-variable/roboto-mono";

// gatsby-browser.js
import React from "react";
import { theme } from "./src/theme";
import { ChakraProvider } from "@chakra-ui/react";

export const wrapPageElement = ({ element, props }) => (
  <ChakraProvider {...props} theme={theme}>
    {element}
  </ChakraProvider>
);
