import { extendTheme } from "@chakra-ui/react";
import { GlobalStyles as styles } from "./styles";
import { ColorStyles as colors } from "./foundations/colors";
import { FontStyles as fonts } from "./foundations/fonts";
import { FontSizesStyles as fontSizes } from "./foundations/fontSizes";
import { BreakpointStyles as breakpoints } from "./foundations/breakpoints";
import { pageWidth, pageGutter } from "./foundations/layout";
import { ButtonStyles as Button } from "./components/button";
import { ToolTipStyles as Tooltip } from "./components/tooltip";
import { AccordionStyles as Accordion } from "./components/accordion";
import { ListStyles as List } from "./components/list";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const theme = extendTheme(
  {
    styles,
    colors,
    fonts,
    fontSizes,
    breakpoints,
    components: {
      Button,
      Tooltip,
      Accordion,
      List,
    },
  },
  withProse()
);

export { theme, pageGutter, pageWidth, breakpoints };
