export const pageWidth = {
  base: "full",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

export const pageGutter = {
  base: "4",
  md: "8",
  lg: "12",
};
