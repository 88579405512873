import { listAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(listAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    marginLeft: 6,
  },
  item: {},
  icon: {
    marginLeft: -6,
    color: "verdigris.500",
  },
});

const sizes = definePartsStyle({
  // Styles for the size variations
});

const variants = definePartsStyle({
  price: {
    item: {
      paddingBottom: 3,
      display: "flex",
      alignItems: "self-start",
    },
    icon: {
      boxSize: 6,
    },
  },
});

const defaultProps = definePartsStyle({
  // The default `size` or `variant` values
});

export const ListStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps,
});
