export const ColorStyles = {
  verdigris: {
    50: "#EBF7F3",
    100: "#CCE6DE",
    200: "#99CDBD",
    300: "#66B59B",
    400: "#339C7A",
    500: "#008359",
    600: "#006947",
    700: "#004F35",
    800: "#003424",
    900: "#001A12",
  },
  verdigrisGradient: {
    50: "linear-gradient(90deg, black 0%, #EBF7F3 100%)",
    100: "linear-gradient(90deg, black 0%, #CCE6DE 100%)",
    200: "linear-gradient(90deg, black 0%, #99CDBD 100%)",
    300: "linear-gradient(90deg, black 0%, #66B59B 100%)",
    400: "linear-gradient(90deg, black 0%, #339C7A 100%)",
    500: "linear-gradient(90deg, black 0%, #008359 100%)",
    600: "linear-gradient(90deg, black 0%, #006947 100%)",
    700: "linear-gradient(90deg, black 0%, #004F35 100%)",
    800: "linear-gradient(90deg, black 0%, #003424 100%)",
    900: "linear-gradient(90deg, black 0%, #001A12 100%)",
  },
};
