import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderColor: "gray.200",
  },
  button: {
    px: 6,
    py: 4,
    gap: 6,
  },
  panel: {
    px: 6,
    py: 4,
  },
});

const sizes = definePartsStyle({
  // Styles for the size variations
});

const variants = definePartsStyle({
  // Styles for the visual style variations
});

const defaultProps = definePartsStyle({
  // The default `size` or `variant` values
});

export const AccordionStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps,
});
