import { defineStyleConfig } from "@chakra-ui/react";

export const ButtonStyles = defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    fontFamily: "heading",
    fontWeight: "semibold",
    borderRadius: "md",
    border: "1px",
    borderStyle: "solid",
    borderColor: "whiteAlpha.300",
  },
  // Styles for the size variations
  sizes: {
    xl: {
      fontSize: "xl",
      px: "6",
      h: "16",
    },
  },
  // Styles for the visual style variations
  variants: {
    solid: {
      boxShadow: "lg",
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: "lg",
    variant: "solid",
    colorScheme: "verdigrisGradient",
  },
});
