export const GlobalStyles = {
  global: {
    "html, body": {
      backgroundColor: "white",
      color: "black",
      scrollBehavior: "smooth",
    },
    ".my-config": {
      "--darkmode": 0,
      "--h-accent": 161,
      "--s-accent": "100%",
      "--l-accent": "26%",
    },
  },
};
