module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-L2DRDVNMN7"],"gtagConfig":{"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pixeon. Durchbrich mit uns die Grenzen der Kreativität.","short_name":"Pixeon","description":"Durchbrich mit uns die Grenzen der Kreativität. Wir verwandeln Ideen in inspirierende Erlebnisse, die Menschen und Marken voranbringen. Mach dein Brand zukunftsbereit mit AI-powered Marketing.","start_url":"/","background_color":"#ffffff","theme_color":"#008359","display":"standalone","icon":"./src/images/icon-path-maskable.png","icon_options":{"purpose":"any"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icon-path*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
